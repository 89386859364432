<template>
  <b-container fluid="xl">
    <!-- <div style="width: 500px; max-width: 100%; margin: 50px auto">
      <b-img
        fluid
        v-if="Locale === 'fr'"
        src="@/assets/travel-4-climate/fr-rgb.png"
        :alt="$t('Travel4Climate.BannerAlt')"
      />
      <b-img
        v-else
        fluid
        src="@/assets/travel-4-climate/eng-rgb.png"
        :alt="$t('Travel4Climate.BannerAlt')"
      />
    </div> -->

    <h1 class="h2">{{ $t("ClimateBook.Header") }}</h1>

    <div v-if="!submissionComplete">
      <p v-html="$t('ClimateBook.Intro.Text')" />

      <hr />

      <validation-observer v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <your-school v-model="model.School" />

          <h2 class="h5">
            {{ $t("ClassesOrGroups.Label") }}
          </h2>
          <validation-provider
            v-slot="{ errors, valid }"
            name="classcollection"
            :rules="{ required: true, min: 1 }"
          >
            <class-collection
              ref="classCollection"
              id="classcollection"
              v-model="model.Classes"
              description-values
            />
            <b-form-invalid-feedback :state="valid">
              <span v-for="(error, index) in errors" :key="index">{{
                $t(error)
              }}</span>
            </b-form-invalid-feedback>
          </validation-provider>

          <h2 class="h5">
            {{ $t("AboutYou") }}
          </h2>

          <!-- <number-of-adults v-model="model.NumAdults" /> -->

          <project-context v-model="model.Context" />

          <language
            :label="$t('ClimateBook.Questions.Language.Text')"
            v-model="model.Language"
          />

          <learned-about-project v-model="model.LearnedAbout" />

          <feedback v-model="model.Feedback" />

          <mailing-list v-model="model.MailingList" />

          <div style="margin-top: 10px">
            <b-button
              id="btn_submit"
              type="submit"
              variant="primary"
              class="mt-2"
              :disabled="saving"
              >{{ $t("Submit") }}</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </div>

    <!-- Confirmation -->
    <div v-else>
      <b-container id="confirmationheader">
        <h3>{{ $t("ClimateBook.Confirmation.Header") }}</h3>
      </b-container>
      <hr />
      <p id="confirmationsub">
        <strong>
          {{ $t("ClimateBook.Confirmation.SubHeader") }}
        </strong>
      </p>
      <p id="confirmationbody" v-html="$t('ClimateBook.Confirmation.Body')" />
      <!-- <b-button
        id="confirmationbtncontainer"
        variant="primary"
        :href="$t('ClimateBook.Confirmation.ButtonLink')"
        size="lg"
      >
        {{ $t("ClimateBook.Confirmation.Button") }}
      </b-button> -->
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { min, numeric, min_value } from "vee-validate/dist/rules";
import ClassCollection from "@/components/ClassCollection.vue";
import ProjectContext from "@/components/questions/ProjectContext.vue";
import LearnedAboutProject from "@/components/questions/LearnedAboutProject.vue";
import NumberOfAdults from "@/components/questions/NumberOfAdults.vue";
import Language from "@/components/questions/Language.vue";
import Feedback from "@/components/questions/Feedback.vue";
import MailingList from "@/components/questions/MailingList.vue";
import YourSchool from "@/components/questions/YourSchool.vue";
import FormBase from "@/types/FormBase";

extend("min", {
  ...min,
  message: "Validation.MinLength",
});

extend("min_value", {
  ...min_value,
  message: "Validation.ProvideNumericGreaterThanZero",
});

extend("numeric", {
  ...numeric,
  message: "Validation.Numeric",
});

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    ClassCollection,
    ProjectContext,
    LearnedAboutProject,
    NumberOfAdults,
    Language,
    Feedback,
    MailingList,
    YourSchool,
  },
})
export default class ClimateBook extends FormBase {
  name = "";
  model: any = {};

  get Form(): string {
    return "ClimateBook";
  }

  get Instance(): string | null {
    return this.$route.params.instance;
  }

  get Locale(): string {
    return this.$i18n.locale;
  }

  mounted() {
    this.GetFormData().then((resp: any) => {
      this.submissionComplete = resp.Submissions > 0;
    });
  }

  onSubmit() {
    const classCollectionValid = (
      this.$refs.classCollection as ClassCollection
    ).validate();

    if (classCollectionValid === true) {
      this.SaveSubmission(this.model);
    }
  }
}
</script>
<style scoped>
.question {
  padding-top: 20px;
}

#form {
  padding: 10px;
}

#submitbtn {
  margin-top: 25px;
}

.binput {
  max-width: 250px;
}
textarea.binput {
  max-width: 100%;
}

hr {
  background-color: rgb(145, 145, 145);
}

#noschool-details:invalid {
  border: 1px solid red;
}

#noschool-details:valid {
  border: 1px solid green;
}

#confirmationheader {
  margin-top: 30px;
  text-align: center;
}

#confirmationsub {
  margin-top: 30px;
}

#confirmationbody {
  margin-top: 30px;
}

#confirmationbtncontainer {
  margin-top: 30px;
}

#headercontainer {
  text-align: center;
}

.question {
  font-weight: bold;
}
</style>
