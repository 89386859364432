<template>
  <b-container fluid="xl">
    <h1>{{ $t("LTSChallenge.Submissions.Header") }}</h1>
    <p>
      {{ $t("LTSChallenge.Submissions.Intro") }}
    </p>
    <table>
      <thead>
        <tr>
          <th>{{ $t("LTSChallenge.EventType") }}</th>
          <th>{{ $t("LTSChallenge.EventDate") }}</th>
          <th>{{ $t("LTSChallenge.EventLocation") }}</th>
          <th>{{ $t("LTSChallenge.EventStatus") }}</th>
          <th>{{ $t("LTSChallenge.RegistrationStatus") }}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(r, idx) in registrations" :key="idx">
          <td>{{ $t(`LTSChallenge.EventTypes.${getEventType(r.Event)}`) }}</td>
          <td>
            {{
              $i18n.locale === "fr"
                ? r.Event?.DisplayDateFrench
                : r.Event?.DisplayDateEnglish
            }}
          </td>
          <td>{{ r.Event?.Location }}</td>
          <td>
            {{ $t(`LTSChallenge.EventStatus.${getEventStatus(r.Event)}`) }}
          </td>
          <td>
            {{
              $t(
                `LTSChallenge.RegistrationStatus.${getRegistrationStatus(
                  r.Status
                )}`
              )
            }}
          </td>
          <td>
            <b-button
              variant="outline-primary"
              @click="onViewRegistrationClicked(r)"
            >
              {{ $t("LTSChallenge.Submissions.View") }}
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>
  </b-container>
</template>

<script lang="ts">
import ChallengeApiService from "@/services/ChallengeApiService";
import LTSCEvent, {
  EventDeliveryModes,
  EventStatus,
} from "@/types/challenge/LTSCEvent";
import LTSCRegistration, {
  RegistrationStatus,
} from "@/types/challenge/LTSCRegistration";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class LTSChallengeSubmissionList extends Vue {
  registrations: LTSCRegistration[] = [];
  challengeApiService: ChallengeApiService = new ChallengeApiService();

  getEventType(e: LTSCEvent | null): string {
    if (!e) {
      return "";
    } else {
      return EventDeliveryModes[e.DeliveryMode];
    }
  }

  getEventStatus(e: LTSCEvent | null): string {
    if (!e) {
      return "";
    } else {
      return EventStatus[e.Status];
    }
  }

  getRegistrationStatus(rs: RegistrationStatus): string {
    return RegistrationStatus[rs];
  }

  mounted() {
    this.challengeApiService.GetRegistrations().then((resp) => {
      if (resp.Success) {
        this.registrations = resp.Value;
        console.log(this.registrations);
      } else {
        console.log(resp);
      }
    });
  }

  onViewRegistrationClicked(r: LTSCRegistration) {
    this.$router.push(`/View/LTSChallenge/${r.Id}`);
  }
}
</script>
<style scoped>
hr {
  background-color: rgb(145, 145, 145);
}

.question {
  padding-top: 20px;
  font-weight: bold;
}

table,
th,
td {
  border: 1px solid black;
  padding: 10px;
  margin-top: 10px;
}

thead {
  background-color: #0053a1;
  color: white;
}

#waitlist_notification {
  color: #0053a1;
  font-weight: bold;
}
</style>
