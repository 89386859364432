<template>
  <b-container fluid="xl">
    <p>{{ $t("FormAdmin.List.Intro") }}</p>

    <b-button variant="primary" @click="onAddClicked">{{
      $t("FormAdmin.List.Add")
    }}</b-button>

    <div class="mt-2">
      <b-table
        responsive
        striped
        hover
        :busy="loading"
        :fields="fields"
        :items="forms"
      >
        <template #cell(IsDirect)="form">
          <b-checkbox type="text" v-model="form.item.IsDirect" disabled />
        </template>
        <template #cell(Actions)="form">
          <b-dropdown right text="Actions">
            <b-dropdown-item @click="onEditClicked(form.item)">{{
              $t("FormAdmin.List.Edit")
            }}</b-dropdown-item>
            <b-dropdown-item @click="copyFormUrlToClipboard(form.item)">{{
              $t("FormAdmin.List.CopyUrl")
            }}</b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import FormApiService from "@/services/FormApiService";
import cDate from "@/components/inputs/cDate.vue";
import { ValidationObserver } from "vee-validate";
import { RouteRecordPublic } from "vue-router";

@Component({
  components: {
    cDate,
    ValidationObserver,
  },
})
export default class FormList extends Vue {
  loading = false;
  formApiService = new FormApiService();
  forms: any[] = [];

  fields: object[] = [
    {
      key: "Name",
      label: this.$root.$t("FormAdmin.List.Name"),
    },
    {
      key: "Version",
      label: this.$root.$t("FormAdmin.List.Version"),
    },
    {
      key: "GrantsRole",
      label: this.$root.$t("FormAdmin.List.GrantsRole"),
    },
    {
      key: "EventName.Name",
      label: this.$root.$t("FormAdmin.List.EventName"),
    },
    {
      key: "EventType.Name",
      label: this.$root.$t("FormAdmin.List.EventType"),
    },
    {
      key: "Program.Name",
      label: this.$root.$t("FormAdmin.List.Program"),
    },
    {
      key: "IsDirect",
      label: this.$root.$t("FormAdmin.List.Direct"),
    },
    {
      key: "Actions",
    },
  ];

  mounted() {
    this.getData();
  }

  formatDate(d: string): string {
    return d.substring(0, d.indexOf("T"));
  }

  getData() {
    this.formApiService.GetForms().then((resp) => {
      if (resp.Success) {
        this.forms = resp.Value;
      }
    });
  }

  onAddClicked() {
    this.$router.push("Forms/Create");
  }

  onEditClicked(f: any) {
    this.$router.push(`Forms/Update/${f.Id}`);
  }

  onEditInstancesClicked(f: any) {
    this.$router.push(`Forms/${f.Id}/Instances`);
  }

  getPathByFormName(form_name: string): string {
    const routes: RouteRecordPublic[] = this.$router.getRoutes();
    const form_route = routes.find((r) => r.name === form_name);
    const url = `${window.location.host}${form_route?.path.replace(
      ":instance?",
      ""
    )}`;

    return url;
  }

  copyFormUrlToClipboard(f: any) {
    const url = this.getPathByFormName(f.Name);
    this.copyToClipboard(url);
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
  }
}
</script>
