<template>
  <div>
    <h1 class="ws-header">{{ $t("OutreachRequest.Header") }}</h1>

    <div v-if="!submissionComplete">
      <b-container>
        <p
          class="h5 mt-5"
          v-html="
            $t('OutreachRequest.Intro.Text', {
              link: $t('OutreachRequest.Intro.Link'),
            })
          "
        />

        <validation-observer ref="form_vp">
          <b-form @submit.prevent>
            <yes-no-radio-options
              id="first-time-participating"
              v-model="model.FirstTimeParticipating"
              :required="true"
              name="FirstTimeParticipating"
              label=""
            >
              <template #label>
                <p class="h5 required-field">
                  {{ $t("FirstTimeParticipating") }}
                </p>
              </template>
            </yes-no-radio-options>

            <h2
              class="h4 mt-4 border-bottom-1 border-right-0 border-left-0 border-top-0 border border-primary mb-3 pb-3"
            >
              {{ $t("OutreachRequest.TextBlocks.ContactInfo") }}
            </h2>

            <p class="h5">
              {{ $t("OutreachRequest.TextBlocks.ContactInfoSubHeader") }}
            </p>

            <div class="row">
              <div class="col-12 col-md-6 col-lg-4">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="FirstName"
                  rules="required"
                >
                  <b-form-group
                    id="contact-first-name-group"
                    :label="$t('OutreachRequest.Questions.FirstName')"
                    label-for="contact-first-name"
                    class="required-field"
                  >
                    <b-form-input
                      id="contact-first-name"
                      class="binput"
                      type="text"
                      v-model="model.Contact.FirstName"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="valid">
                    <span v-for="(error, index) in errors" :key="index">{{
                      $t(error)
                    }}</span>
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="col">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="LastName"
                  rules="required"
                >
                  <b-form-group
                    id="contact-last-name-group"
                    :label="$t('OutreachRequest.Questions.LastName')"
                    label-for="contact-last-name"
                    class="required-field"
                  >
                    <b-form-input
                      id="contact-last-name"
                      class="binput"
                      type="text"
                      v-model="model.Contact.LastName"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="valid">
                    <span v-for="(error, index) in errors" :key="index">{{
                      $t(error)
                    }}</span>
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6 col-lg-4">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Email"
                  rules="required"
                >
                  <b-form-group
                    id="contact-email-group"
                    :label="$t('OutreachRequest.Questions.Email')"
                    label-for="contact-email"
                    class="required-field"
                  >
                    <b-form-input
                      id="contact-email"
                      class="binput"
                      type="text"
                      v-model="model.Contact.Email"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="valid">
                    <span v-for="(error, index) in errors" :key="index">{{
                      $t(error)
                    }}</span>
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="col">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="PhoneNumber"
                >
                  <b-form-group
                    id="contact-phone-group"
                    :label="$t('OutreachRequest.Questions.PhoneNumber')"
                    label-for="contact-phone"
                  >
                    <b-form-input
                      id="contact-phone"
                      class="binput"
                      type="text"
                      v-model="model.Contact.PhoneNumber"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="valid">
                    <span v-for="(error, index) in errors" :key="index">{{
                      $t(error)
                    }}</span>
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </div>

            <h2
              class="h4 mt-4 border-bottom-1 border-right-0 border-left-0 border-top-0 border border-primary mb-3 pb-3"
            >
              {{ $t("OutreachRequest.TextBlocks.SchoolInfo") }}
            </h2>

            <!-- School -->
            <your-school v-model="model.Organization" @input="onSchoolInput" />

            <h2
              class="h4 mt-4 border-bottom-1 border-right-0 border-left-0 border-top-0 border border-primary mb-3 pb-3"
            >
              {{ $t("OutreachRequest.OutreachInformation") }}
            </h2>

            <p class="h5 required-field">
              {{ $t("OutreachRequest.Questions.VisitLanguage") }}
            </p>

            <language
              class="mb-4"
              hide-label
              :options="language_options"
              v-model="model.LanguageId"
            />

            <div v-if="model.LanguageId === other_language_id" class="mb-4">
              <validation-provider
                v-slot="{ errors, valid }"
                name="OtherLanguage"
                rules="required"
              >
                <b-form-group
                  id="other-language-group"
                  :label="$t('OutreachRequest.Questions.OtherLanguage')"
                  label-for="other-language"
                  class="required-field"
                >
                  <b-form-input
                    id="other-language"
                    type="text"
                    v-model="model.OtherLanguage"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="valid">
                  <span v-for="(error, index) in errors" :key="index">{{
                    $t(error)
                  }}</span>
                </b-form-invalid-feedback>
              </validation-provider>
            </div>

            <p class="h5 required-field">
              {{ $t("OutreachRequest.Questions.OutreachType") }}
            </p>

            <validation-provider
              v-slot="{ errors, valid }"
              name="OutreachType"
              :rules="{ required: true }"
            >
              <b-form-group>
                <b-form-radio-group
                  id="outreach-type"
                  v-model="model.OutreachType"
                  :options="outreach_type_options"
                  stacked
                ></b-form-radio-group>
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>

            <div
              v-if="
                model.OutreachType !== undefined &&
                model.OutreachType !== 1 &&
                model.Organization &&
                model.Organization.Address
              "
            >
              <p class="h5 mt-5 required-field">
                {{
                  $t("OutreachRequest.Questions.UseSchoolAddressAsEventAddress")
                }}
              </p>
              <validation-provider
                v-slot="{ errors, valid }"
                name="SchoolAddressAsEventAddress"
                :rules="{ required: true }"
              >
                <b-form-group>
                  <b-form-radio-group
                    id="school-address-as-event-address"
                    v-model="model.OrgAddressAsEventAddress"
                    :options="yes_no_options"
                    stacked
                  ></b-form-radio-group>
                </b-form-group>
                <b-form-invalid-feedback :state="valid">
                  <span v-for="(error, index) in errors" :key="index">{{
                    $t(error)
                  }}</span>
                </b-form-invalid-feedback>
              </validation-provider>

              <div v-if="model.OrgAddressAsEventAddress === false">
                <b-row>
                  <b-col cols="9">
                    <b-form-group
                      id="event-address-group"
                      :label="$t('OutreachRequest.Questions.EventAddress')"
                      label-for="event-address"
                      class="required-field"
                    >
                      <b-form-input
                        id="event-address"
                        type="text"
                        style="width: 100%"
                        v-model="model.EventAddress.Address"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3">
                    <b-form-group
                      id="event-province-group"
                      :label="$t('OutreachRequest.Questions.EventProvince')"
                      label-for="event-province"
                      class="required-field"
                    >
                      <b-form-input
                        id="event-province"
                        class="binput"
                        type="text"
                        v-model="model.EventAddress.Province"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="3">
                    <b-form-group
                      id="event-city-group"
                      :label="$t('OutreachRequest.Questions.EventCity')"
                      label-for="event-city"
                      class="required-field"
                    >
                      <b-form-input
                        id="event-city"
                        class="binput"
                        type="text"
                        v-model="model.EventAddress.City"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="3">
                    <postal-code-input
                      :label="$t('OutreachRequest.Questions.EventPostalCode')"
                      v-model="model.EventAddress.PostalCode"
                    />
                  </b-col>
                </b-row>
              </div>
            </div>

            <!-- demogs -->
            <yes-no-radio-options
              id="is-specific-demogs"
              class="mt-4"
              :required="true"
              name="SpecificDemogs"
              v-model="model.SpecificDemographics"
              label=""
            >
              <template #label>
                <p class="h5 required-field">
                  {{ $t("OutreachRequest.TextBlocks.SpecificDemographics") }}
                </p>
              </template>
            </yes-no-radio-options>

            <div v-if="model.SpecificDemographics === true">
              <p class="mt-4 h5 font-italic">
                {{ $t("OutreachRequest.Questions.Demographics") }}
              </p>
              <p class="h5 mt-4">
                {{ $t("OutreachRequest.TextBlocks.Demographics.SelectAll") }}
              </p>
              <b-form-group>
                <b-form-checkbox-group
                  id="demographics"
                  v-model="model.Demographics.Groups"
                  :options="demographics_options"
                  stacked
                ></b-form-checkbox-group>
              </b-form-group>

              <b-form-group
                id="demog-popdensity-group"
                :label="$t('OutreachRequest.Questions.PopulationDensity')"
                label-for="demog-popdensity"
                class="col-12 col-md-6"
              >
                <b-form-select
                  id="demog-popdensity"
                  :options="pop_density_options"
                  v-model="model.Demographics.PopulationDensityId"
                >
                  <template #first>
                    <b-form-select-option :value="undefined">{{
                      $t("PleaseSelectAnOption")
                    }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group
                id="demog-gender-group"
                :label="$t('OutreachRequest.Questions.GenderIdentity')"
                label-for="demog-gender"
                class="col-12 col-md-6"
              >
                <b-form-select
                  id="demog-gender"
                  :options="gender_identity_options"
                  v-model="model.Demographics.GenderIdentityId"
                >
                  <template #first>
                    <b-form-select-option :value="undefined">{{
                      $t("PleaseSelectAnOption")
                    }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <!-- end demogs -->

            <p class="h5 mt-4 required-field">
              {{ $t("OutreachRequest.Questions.SpecifySite") }}
            </p>
            <validation-provider
              v-slot="{ errors, valid }"
              name="SpecifySite"
              :rules="{ required: true }"
            >
              <b-form-group>
                <b-form-radio-group
                  id="specify-site"
                  v-model="model.SpecifySite"
                  :options="yes_no_options"
                  stacked
                ></b-form-radio-group>
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>

            <div v-if="model.SpecifySite === true">
              <org-region-select
                id="site-prov-org-filter"
                class="col-12 col-md-6"
                :label="$t('OutreachRequest.Questions.SiteProvinceFilter')"
                v-model="site_prov_filter"
              >
                <template #first-item>
                  <b-form-select-option :value="''">{{
                    $t("PleaseSelectAnOption")
                  }}</b-form-select-option>
                </template>
              </org-region-select>
              <b-form-group
                id="site-group"
                :label="$t('OutreachRequest.Questions.Site')"
                :description="$t('OutreachRequest.Questions.Site.Hint')"
                label-for="site-select"
                class="col-12 col-md-6"
              >
                <b-form-select
                  id="site-select"
                  :options="site_options"
                  v-model="model.SiteId"
                  @input="onSiteIdSelected"
                >
                  <template #first>
                    <b-form-select-option :value="undefined">{{
                      $t("PleaseSelectAnOption")
                    }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </div>

            <div
              v-if="
                selected_site_status.Status &&
                selected_site_status.Status === waitlisted_status
              "
              class="mt-4 ml-4"
            >
              <p>
                {{
                  $t("OutreachRequest.TextBlocks.SiteWaitlistedNotification")
                }}
              </p>
            </div>

            <h2
              class="h4 mt-4 border-bottom-1 border-right-0 border-left-0 border-top-0 border border-primary mb-3 pb-3"
            >
              {{ $t("OutreachRequest.TextBlocks.ClassAndWorkshopInfo") }}
            </h2>
            <class-collection
              ref="classCollection"
              id="classcollection"
              v-model="model.Classes"
              description-values
            >
              <template #class-append="{ item }">
                <h2 class="h5" style="font-weight: 800">
                  {{ $t("OutreachRequest.Questions.Workshop") }}
                </h2>
                <template
                  v-if="
                    model.SpecifySite && model.SiteId && kit_options.length > 0
                  "
                >
                  <p>
                    {{ $t("OutreachRequest.TextBlocks.KitSelect") }}
                  </p>
                  <div class="row mb-4">
                    <div class="col-12 col-md-6 col-lg-4">
                      <b-form-group>
                        <b-form-select
                          :options="kit_options"
                          v-model="item.KitId"
                        >
                          <template #first>
                            <b-form-select-option :value="undefined">{{
                              $t("PleaseSelectAnOption")
                            }}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <div class="col-12">
                      <b-button
                        class="mt-4"
                        variant="link"
                        @click="viewing_kits = true"
                      >
                        {{ $t("OutreachRequest.ViewAllWorkshops") }}
                      </b-button>
                    </div>
                  </div>
                  <b-form-group
                    :label="$t('OutreachRequest.TextBlocks.ClassRequestInfo')"
                    class="h5 font-italic"
                  >
                    <b-form-textarea
                      class="binput"
                      style="min-width: 100%; min-height: 150px"
                      v-model="item.ClassWorkshopInfo"
                    />
                  </b-form-group>
                </template>
                <b-form-group
                  :label="
                    model.SpecifySite && model.SiteId
                      ? $t('OutreachRequest.TextBlocks.ClassSpecifyTopic')
                      : $t('OutreachRequest.TextBlocks.ClassSpecifyTopicNoSite')
                  "
                  class="h5 font-italic"
                >
                  <b-form-textarea
                    class="binput"
                    style="min-width: 100%; min-height: 150px"
                    v-model="item.ClassSpecifyTopic"
                  />
                </b-form-group>
              </template>
            </class-collection>

            <!-- view kits modal -->
            <b-modal
              v-model="viewing_kits"
              size="xl"
              :title="$t('OutreachRequest.KitsAvailable')"
            >
              <table class="table table-striped kits-table">
                <thead>
                  <tr>
                    <th>{{ $t("OutreachRequest.Kits.Name") }}</th>
                    <th>{{ $t("OutreachRequest.Kits.Description") }}</th>
                    <th>{{ $t("OutreachRequest.Kits.Duration") }}</th>
                    <th>{{ $t("OutreachRequest.Kits.Language") }}</th>
                    <th>{{ $t("OutreachRequest.Kits.Grades") }}</th>
                    <th>{{ $t("OutreachRequest.Kits.Subjects") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(k, idx) in kit_table_items" :key="idx">
                    <td>{{ k.Name }}</td>
                    <td>{{ k.Description }}</td>
                    <td>{{ k.Duration }}</td>
                    <td>{{ k.Language }}</td>
                    <td>
                      <ul class="table-ul">
                        <li v-for="(g, gi) in k.Grades" :key="gi">
                          {{ g }}
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul class="table-ul">
                        <li v-for="(s, si) in k.Subjects" :key="si">
                          {{ s }}
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <template #modal-footer>
                <b-button
                  variant="outline-primary"
                  @click="viewing_kits = false"
                  >{{ $t("Ok") }}</b-button
                >
              </template>
            </b-modal>

            <h2
              class="h4 mt-4 pt-4 border-bottom-1 border-right-0 border-left-0 border-top-0 border border-primary mb-3 pb-3"
            >
              {{ $t("OutreachRequest.TextBlocks.AvailableTimes") }}
            </h2>

            <h5 class="required-field font-italic">
              {{ $t("OutreachRequest.Questions.AvailableTimes") }}
            </h5>
            <b-form-group id="available-times-group" class="required-field">
              <b-form-textarea
                id="available-times"
                class="binput"
                style="min-width: 100%; min-height: 150px"
                v-model="model.AvailableTimes"
              />
            </b-form-group>

            <h5 class="mt-5 font-italic">
              {{ $t("OutreachRequest.Questions.LogisticDetails") }}
            </h5>
            <b-form-group id="logistic-details-group" class="required-field">
              <b-form-textarea
                id="logistic-details"
                class="binput"
                style="width: 100%; min-height: 150px"
                v-model="model.LogisticDetails"
              />
            </b-form-group>

            <b-button
              id="btn_submit"
              class="mt-4 btn-xl"
              variant="primary"
              :disabled="saving"
              @click="onSubmit"
              >{{ $t("Submit") }}</b-button
            >
          </b-form>
        </validation-observer>
      </b-container>
    </div>

    <!-- Confirmation -->
    <div v-else class="confirmation">
      <p class="mb-5">
        {{ $t("OutreachRequest.Confirmation.Body") }}
      </p>
      <b-icon-check-circle class="confirmation-icon" />
      <p
        class="mt-5"
        v-html="
          $t('OutreachRequest.Confirmation.Append', {
            Link: $t('OutreachRequest.Confirmation.Link'),
            LinkText: $t('OutreachRequest.Confirmation.LinkText'),
          })
        "
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { min, numeric, min_value } from "vee-validate/dist/rules";
import SchoolLookup from "@/components/school/SchoolLookup.vue";
import SchoolInfo from "@/components/school/SchoolInfo.vue";
import ClassCollection from "@/components/ClassCollection.vue";
import ProjectContext from "@/components/questions/ProjectContext.vue";
import LearnedAboutProject from "@/components/questions/LearnedAboutProject.vue";
import OrgRegionSelect from "@/components/school/OrgRegionSelect.vue";
import PostalCodeInput from "@/components/PostalCodeInput.vue";
import VmsApiService from "@/services/VmsApiService";
import MailingList from "@/components/questions/MailingList.vue";
import YourSchool from "@/components/questions/YourSchool.vue";
import FormBase from "@/types/FormBase";
import YesNoRadioOptions from "@/components/questions/YesNoRadioOptions.vue";
import Language from "@/components/questions/Language.vue";
import OrganizationApiService from "@/services/OrganizationApiService";

extend("min", {
  ...min,
  message: "Validation.MinLength",
});

extend("min_value", {
  ...min_value,
  message: "Validation.ProvideNumericGreaterThanZero",
});

extend("numeric", {
  ...numeric,
  message: "Validation.Numeric",
});

enum RequestStatus {
  Open,
  Waitlisted,
  Closed,
}

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    SchoolLookup,
    SchoolInfo,
    ClassCollection,
    ProjectContext,
    LearnedAboutProject,
    OrgRegionSelect,
    PostalCodeInput,
    MailingList,
    YourSchool,
    YesNoRadioOptions,
    Language,
  },
})
export default class OutreachRequest extends FormBase {
  name = "";
  model: any = {
    Contact: {},
    EventAddress: {},
    Demographics: {},
    Organization: {},
  };

  vmsApiService: VmsApiService = new VmsApiService();
  vmsGenderIdentities: any[] = [];
  vmsPopulationDensities: any[] = [];
  vmsGradesMap: Map<string, number> = new Map();
  vmsLanguages: any[] = [];

  vmsSites: any[] = [];
  vmsKits: any[] = [];
  viewing_kits = false;

  orgApiService: OrganizationApiService = new OrganizationApiService();
  site_prov_filter = "";

  get Form(): string {
    return "OutreachRequest";
  }

  get Instance(): string | null {
    return this.$route.params.instance;
  }

  get yes_no_options() {
    return [
      { text: this.$root.$t("YesNoOptions.Yes"), value: true },
      { text: this.$root.$t("YesNoOptions.No"), value: false },
    ];
  }

  get outreach_type_options() {
    return [
      {
        text: this.$root.$t("OutreachRequest.Options.OutreachTypes.InPerson"),
        value: 0,
      },
      {
        text: this.$root.$t("OutreachRequest.Options.OutreachTypes.Virtual"),
        value: 1,
      },
      {
        text: this.$root.$t("OutreachRequest.Options.OutreachTypes.Either"),
        value: 2,
      },
    ];
  }

  get demographics_options() {
    return [
      {
        text: this.$root.$t(
          "OutreachRequest.Options.Demographics.LowSocioEconomicStatus"
        ),
        value: "low socio economic status",
      },
      {
        text: this.$root.$t("OutreachRequest.Options.Demographics.BlackYouth"),
        value: "black youth",
      },
      {
        text: this.$root.$t(
          "OutreachRequest.Options.Demographics.VisibleMinority"
        ),
        value: "visible minority",
      },
      {
        text: this.$root.$t(
          "OutreachRequest.Options.Demographics.IndigenousYouth"
        ),
        value: "indigenous youth",
      },
    ];
  }

  get pop_density_options() {
    return this.vmsPopulationDensities
      .sort((a, b) => {
        if (a.SortOrder < b.SortOrder) return -1;
        else if (a.SortOrder > b.SortOrder) return 1;
        else return 0;
      })
      .map((e) => {
        const lt =
          e.Enumeration?.LocalizationKey?.LocalizationStrings?.find(
            (ls: any) => ls.Locale.Locale === this.$i18n.locale
          )?.Text || "";
        return {
          text: lt,
          value: e.EnumerationId,
        };
      });
  }

  get gender_identity_options() {
    return this.vmsGenderIdentities
      .sort((a, b) => {
        if (a.SortOrder < b.SortOrder) return -1;
        else if (a.SortOrder > b.SortOrder) return 1;
        else return 0;
      })
      .map((e) => {
        const lt =
          e.Enumeration?.LocalizationKey?.LocalizationStrings?.find(
            (ls: any) => ls.Locale.Locale === this.$i18n.locale
          )?.Text || "";
        return {
          text: lt,
          value: e.EnumerationId,
        };
      });
  }

  get locale(): string {
    return this.$i18n.locale;
  }

  get site_options(): any[] {
    return this.vmsSites
      .filter(
        (s) => s.Type === 0 && s.LatestStatus.Status !== RequestStatus.Closed
      )
      .filter((s) =>
        this.site_prov_filter
          ? s.Organization?.RegionId === this.site_prov_filter
          : true
      )
      .map((s) => {
        const waitlisted: boolean =
          s.LatestStatus.Status === RequestStatus.Waitlisted;
        const name = waitlisted
          ? `${s.Name} (${this.$t("OutreachRequest.Status.Waitlisted")})`
          : s.Name;
        return {
          text: name,
          value: s.Id,
        };
      });
  }

  get site_id(): string {
    const national_site = this.vmsSites.find((s) => s.Type === 2);
    return this.model.SpecifySite ? this.model.SiteId : national_site?.Id;
  }

  get selected_site_status() {
    if (this.model.SpecifySite) {
      const site = this.vmsSites.find((s) => s.Id === this.model.SiteId);
      return site ? site.LatestStatus : {};
    } else return {};
  }

  get waitlisted_status(): RequestStatus {
    return RequestStatus.Waitlisted;
  }

  get other_language_id(): string {
    const o_id =
      this.vmsLanguages.find((l) => l.Enumeration?.Value === "Other")
        ?.EnumerationId ?? "";
    return o_id;
  }

  get language_options(): any[] {
    const langs = this.vmsLanguages?.map((l) => {
      const text =
        l.Enumeration?.LocalizationKey.LocalizationStrings.find(
          (ls: any) => ls.Locale.Locale === this.$i18n.locale
        )?.Text ?? "";
      return {
        Id: l.EnumerationId,
        Text: text,
        SortOrder: l.SortOrder,
      };
    });

    return langs
      .sort((a, b) => {
        if (a.SortOrder > b.SortOrder) return 1;
        else if (a.SortOrder < b.SortOrder) return -1;
        else return 0;
      })
      .map((l) => {
        return {
          text: l.Text,
          value: l.Id,
        };
      });
  }

  get kit_options(): any[] {
    return this.vmsKits
      .filter((k) =>
        this.locale === "fr" ? k.Language !== 0 : k.Language !== 1
      )
      .map((k) => {
        return {
          text: this.locale === "fr" ? k.FrenchName : k.EnglishName,
          value: k.Id,
        };
      });
  }

  get kit_table_items(): any[] {
    return this.vmsKits
      .filter(
        (k) =>
          k.SiteId === this.site_id &&
          (this.locale === "fr" ? k.Language !== 0 : k.Language !== 1)
      )
      .map((k) => {
        const duration_ls = k.Duration.LocalizationKey.LocalizationStrings.find(
          (ls: any) => ls.Locale.Locale === this.locale
        );

        const desc =
          this.locale === "fr" ? k.FrenchDescription : k.EnglishDescription;

        const name = this.locale === "fr" ? k.FrenchName : k.EnglishName;

        const lang = k.Language === 1 ? "French" : "English";

        let grades: string[] = [];
        if (k.GradesHeader && k.GradesHeader.Items) {
          grades = k.GradesHeader.Items.map((gi: any) => {
            return gi.Enumeration.LocalizationKey.LocalizationStrings.find(
              (ls: any) => ls.Locale.Locale === this.locale
            ).Text;
          });
        }

        let subjects: string[] = [];
        if (k.SubjectsHeader && k.SubjectsHeader.Items) {
          subjects = k.SubjectsHeader.Items.map((s: any) => {
            return s.Enumeration.LocalizationKey.LocalizationStrings.find(
              (ls: any) => ls.Locale.Locale === this.locale
            ).Text;
          });
        }

        return {
          Name: name,
          Description: desc,
          Duration: duration_ls.Text,
          Language: lang,
          Grades: grades,
          Subjects: subjects,
        };
      });
  }

  mounted() {
    this.GetFormData();

    this.vmsApiService
      .GetEnumerationListsBySystemName([
        "PopulationDensities",
        "YouthGroupGenderIdentity",
        "Grades",
        "Languages",
      ])
      .then((resp) => {
        const lists: any[] = resp.Value;
        this.vmsGenderIdentities =
          lists.find((x) => x.SystemName === "YouthGroupGenderIdentity")
            ?.EnumerationItems || [];

        this.vmsPopulationDensities =
          lists.find((x) => x.SystemName === "PopulationDensities")
            ?.EnumerationItems || [];

        this.vmsLanguages =
          lists.find((x) => x.SystemName === "Languages")?.EnumerationItems ||
          [];

        const grades =
          lists.find((x) => x.SystemName === "Grades")?.EnumerationItems || [];
        grades.forEach((g: any) => {
          this.vmsGradesMap.set(g.EnumerationId, g.SortOrder);
        });
      });

    this.vmsApiService.GetSites().then((resp) => {
      this.vmsSites = resp.Value;
    });
  }

  onSiteIdSelected() {
    this.vmsApiService.GetKits(this.model.SiteId).then((resp) => {
      if (resp.Success) {
        this.vmsKits = resp.Value.filter((s) => s.Visibility === true);

        //sort grades & kits by grade
        this.vmsKits.forEach((k: any) => {
          if (k.GradesHeader === null || k.GradesHeader.Items.length === 0) {
            return 0;
          }

          k.GradesHeader.Items = k.GradesHeader.Items.sort((a: any, b: any) => {
            const a_so: number = this.vmsGradesMap.get(a.EnumerationId) || 0;
            const b_so: number = this.vmsGradesMap.get(b.EnumerationId) || 0;
            if (a_so > b_so) return 1;
            else if (a_so < b_so) return -1;
            else return 0;
          });
        });

        this.vmsKits = this.vmsKits.sort((a: any, b: any) => {
          if (a.GradesHeader === null || a.GradesHeader.Items.length === 0) {
            return 1;
          } else if (
            b.GradesHeader === null ||
            b.GradesHeader.Items.length === 0
          ) {
            return -1;
          }

          const a_gi = a.GradesHeader.Items[0];
          const b_gi = b.GradesHeader.Items[0];
          const a_so = this.vmsGradesMap.get(a_gi.EnumerationId) || 0;
          const b_so = this.vmsGradesMap.get(b_gi.EnumerationId) || 0;
          if (a_so > b_so) return 1;
          else if (a_so < b_so) return -1;
          else return 0;
        });
      }
    });
  }

  onSchoolInput() {
    const r_id = this.model.Organization?.RegionId || "";
    this.site_prov_filter = r_id;
  }

  onSubmit() {
    (this.$refs.form_vp as any).validate().then((success: any) => {
      if (success) {
        this.SaveSubmission(this.model);
      } else {
        (this as any).$ScrollToFirstError();
      }
    });
  }
}
</script>
<style scoped>
#form {
  padding: 10px;
}

.binput {
  max-width: 250px;
}
textarea.binput {
  max-width: 100%;
}

hr {
  background-color: rgb(145, 145, 145);
}

th {
  background-color: white;
  color: black;
}

th,
td {
  border: 1px solid blue;
}

@media (max-width: 1199px) {
  .two-col-checkboxes .custom-control-inline,
  .three-col-checkboxes .custom-control-inline {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .two-col-checkboxes .custom-control-inline {
    margin-right: 1%;
    width: 49%;
  }

  .three-col-checkboxes .custom-control-inline {
    margin-right: 1%;
    width: 32%;
  }
}

.table-ul {
  padding-left: 15px;
  padding-right: 15px;
}

.ws-header {
  text-align: center;
  color: white;
  background-color: #0054a4;
  padding: 20px;
}
.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: #9fbee36b;
}

.confirmation {
  text-align: center;
  background-color: #9fbee36b;
  padding: 20px;
}

.confirmation-icon {
  font-size: 8em;
  color: #0054a4;
}
</style>
