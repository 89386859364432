import BaseApiService from "./BaseApiService";
import axios from "axios";
import GetOrganizationResponse from "@/types/GetOrganizationResponse";
import UseCaseResponse from "@/types/UseCaseResponse";
import GetOrgEnumResponse from "@/types/GetOrgEnumResponse";
/* import GetOrgEnumResponse from "@/types/Organization/GetOrgEnumResponse";
import UpdateOrganizationRequest from "@/types/Organization/UpdateOrganizationRequest";
import PatchOrganizationRequest from "@/types/Organization/PatchOrganizationRequest";
import OrganizationFileUpdateHeader from "@/types/Organization/OrganizationFileUpdateHeader"; */

export default class OrganizationApiService extends BaseApiService {
  cache: any = {};

  async GetOrganizations(
    query: string,
    type?: string,
    status?: number
  ): Promise<UseCaseResponse<any[]>> {
    await this.defineHeader();

    let url = `${this.url}/api/Organization?query=${query}&includeUnverified=true`;
    if (type !== undefined) {
      url += `&type=${type}`;
    }

    if (status !== undefined) {
      url += `&status=${status}`;
    }

    return axios
      .get(url)
      .then((resp) => resp.data as UseCaseResponse<GetOrganizationResponse[]>);
  }

  /* async GetOrganizationById(
    id: string
  ): Promise<UseCaseResponse<GetOrganizationResponse>> {
    if (this.cache[id]) return this.cache[id];

    await this.defineHeader();
    return axios.get(`${this.url}/api/Organization/${id}`).then(resp => {
      this.cache[id] = resp.data;
      return resp.data as UseCaseResponse<GetOrganizationResponse>;
    });
  } */

  async GetEnumerationList(
    desc: string,
    locale = ""
  ): Promise<UseCaseResponse<GetOrgEnumResponse[]>> {
    await this.defineHeader();
    let url = `${this.url}/api/Organization/Enumeration/${desc}`;
    if (locale.length > 0) {
      url += `?locale=${locale}`;
    }

    return axios.get(url).then((resp) => {
      return resp.data as UseCaseResponse<GetOrgEnumResponse[]>;
    });
  }
}
