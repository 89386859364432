import UseCaseResponse from "@/types/UseCaseResponse";
import axios from "axios";
import BaseApiService from "./BaseApiService";

export default class LocalizationApiService extends BaseApiService {
  async ProcessLocalizationFile(
    group_name: string,
    localeObject: object
  ): Promise<UseCaseResponse<object>> {
    return axios
      .post(
        `${this.url}/api/localizations/Localization/${group_name}`,
        localeObject
      )
      .then((resp) => resp.data as UseCaseResponse<object>);
  }

  async PatchLocalizationFile(
    group_name: string,
    localeObject: object
  ): Promise<UseCaseResponse<number>> {
    await this.defineHeader();
    return axios
      .patch(
        `${this.url}/api/localizations/Localization/${group_name}`,
        localeObject
      )
      .then((resp) => resp.data as UseCaseResponse<number>);
  }
}
